.orderItemRow {
  & > td {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(22, 29, 22, 0.2);
  }
}

.uploadButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #199B55;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.5rem;

  cursor: pointer;
}

.addButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  background: #199B55;
  color: white;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.5rem;

  cursor: pointer;
}

.deleteButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid rgba(22, 29, 22, 0.4);
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;

  cursor: pointer;
}

.img {
  width: 1rem;
  height: 1rem;
}

.productName {
  width: 8rem;
}

.imageCell {
  display: table-cell;
  text-align: center;
}

.fileNameCell {
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.quantity {
  display: inline;
  width: 3rem;
  border: none;
  font-size: 22px;
  text-align: center;
  margin-left: 0.5rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type=number].quantity {
  -moz-appearance: textfield;
}
