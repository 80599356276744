.orderPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F7F5F2;
  padding-bottom: 96px;
}

.main {
  width: calc(100% - 40px);
  max-width: 1060px;
  padding: 96px 20px 0;
}

.description {
  width: calc(100% - 40px);
  max-width: 1060px;
  padding: 20px;
}

.title {
  font-family: 'Source Serif Pro', 'sans-serif';
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  margin-block: 0;
  margin-inline: 0;
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.orderCheckoutRow {
  max-width: 1060px;
  padding: 0 20px;

  @media (min-width:768px) {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
  }
}


.orderCheckout {
  @media (min-width:768px) {
    margin-left: auto;
  }
}

.paymentTitle {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
}

.payment {

  max-width: 1060px;
  padding: 1.25rem 20px;

  @media (min-width:768px) {
    width: 80%;
  }
}