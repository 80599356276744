.information {
  font-weight: 500;
  line-height: 140%;

  @media (min-width:768px) {
    font-size: 26px;
    max-width: 530px;
  }

  ul {
    list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDIxQzE2LjUyMjggMjEgMjEgMTYuNTIyOCAyMSAxMUMyMSA1LjQ3NzE1IDE2LjUyMjggMSAxMSAxQzUuNDc3MTUgMSAxIDUuNDc3MTUgMSAxMUMxIDE2LjUyMjggNS40NzcxNSAyMSAxMSAyMVoiIGZpbGw9IiMxNjFEMTYiIGZpbGwtb3BhY2l0eT0iMC4yIi8+CjxwYXRoIGQ9Ik02LjUgMTFMOS41IDE0TDE1LjUgOE0yMSAxMUMyMSAxNi41MjI4IDE2LjUyMjggMjEgMTEgMjFDNS40NzcxNSAyMSAxIDE2LjUyMjggMSAxMUMxIDUuNDc3MTUgNS40NzcxNSAxIDExIDFDMTYuNTIyOCAxIDIxIDUuNDc3MTUgMjEgMTFaIiBzdHJva2U9IiMyQTM4MkEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
    padding-inline-start: 25px;

    & > li {
      font-style: normal;
      font-weight: 500;
      line-height: 160%;

      padding-top: 0.5rem;
      padding-left: 1.125rem;

      @media (min-width:768px) {
        line-height: 140%;
      }
    }
  }
}