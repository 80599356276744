.aboutProduct {
  display: flex;
  justify-content: center;
  background: #e7dccb;

  width: 100%;

  h2, .title {
    font-family: 'Source Serif Pro';
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #2A382A;
  }
}

.wrapper {
  max-width: 1060px;
  gap: 1.25rem;
  width: 100%;
  padding: 2.5rem 1.25rem 3rem;
}

.top {
  margin-bottom: 3rem;
  line-height: 140%;
  color: #2A382A;
  padding: 0 1.25rem;
}


.subtitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 1.25rem;
}

.link {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #2A382A;
}

.pricesWrapper {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
}

.loginButton {
  margin-top: 2.5rem;
  max-width: 10.5rem;
  margin-inline: auto;

  @media (min-width:768px) {
    font-size: 22px;
  }
}