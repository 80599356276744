.table {
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-top: 1rem;
    
  @media (min-width:768px) {
    font-size: 20px;
  }

  & tr {
    line-height: 120%;
    height: 3rem;

    @media (min-width:768px) {
     height: 2rem;
    }
  }
  & td {
      border-top: 1px solid rgba(22, 29, 22, 0.2);
      padding: 1rem;
  }      
}

.sentDate, .subject {
    display: inline-block;
}

.sentDate {
    margin-right: 1rem;
    font-weight: 600;
}

.body {
    font-style: italic;
    padding: 0 1rem;
    margin-top: 1rem;
    border: 1px solid rgba(22, 29, 22, 0.1);    
}