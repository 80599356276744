.header {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 10;
}

.container {
  display: flex;
  align-items: center;
  max-width: 1276px;
  width: 100%;
  padding: 1rem 1.125rem;

  @media (min-width:768px) {
    padding: 1.5rem 5.125rem;
  }
}

.logo {
  margin-right: 1rem;
  width: 222px;
  height: 32px;
  @media (min-width:768px) {
    width: 250px;
    height: 36px;
  }
}

.contact,
.logout {
  display: block;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
  cursor: pointer;

  &, &:visited {
    color: #2A382A;
  }
}

.contact {
  margin-left: auto;
}

.logout {
  margin-left: 1rem;
}

