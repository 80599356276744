.addressField {
  display: flex;
  flex-direction: column;

}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #161D16;
}

.input {
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 29, 22, 0.6);
}