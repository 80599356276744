.footer {
  background: #2A382A;
  padding: 2.5rem 1.25rem;
}

.wrapper {
  display: flex;
  align-self: stretch;
  justify-content: center;

  margin-top: auto;

  @media (min-width:768px) {
    padding: 3.5rem 5.125rem;
  }
}

.container {
  flex: 1;
  gap: 1.25rem;

  @media (min-width:768px) {
    display: flex;
    max-width: 1276px;
  }
}

.menu {
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 672px;
  margin-top: 1rem;

  @media (min-width:768px) {
    margin-top: 0;
  }
}

.logoColumn  {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
}

.logo {
  width: 194px;
  height: 28px;

  @media (min-width:768px) {
    width: 221px;
    height: 32px;
  }
}

.copy {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #F7F5F2;
  margin-top: 6.5rem;

  @media (min-width:768px) {
    display: none;
    margin-top: 0;
    font-size: 14px;
  }
}

.copyHideMobile {
  display: none;
  @media (min-width:768px) {
    display: block;
  }
}

.links {
  width: 100%;
  max-width: 304px;
}

.link {
  display: block;
  margin-top: 0.5rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration: none;
  &, &:visited {
    color: #FFFFFF;
  }

  @media (min-width:768px) {
    margin-top: 1rem;
  }
}

.paymentLogos {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
  align-items: center;

  @media (min-width:768px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    gap: 5rem;
  }
}

.paymentLogo {
  max-height: 2rem;
}


