.shippingAddress {
  max-width: 32.875rem;
}

.title {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
}

.box {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.secondTitle {
  ont-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}
