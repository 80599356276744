.printPage {
  background-color: #F7F5F2;
  padding-bottom: 96px;
  font-size: 18px;
}

.description {
  margin: 1rem;
}

.ordersTable {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.col {
  flex: 25%;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  height: 3rem;
}

.shipped, .ordered {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.order {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.downloadOderedButton {
  background: #b1b1b1; 
  width: 150px;   
}

.loader {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid;
  border-color: #F65611 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 1.5rem 5.45rem;  
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

hr {
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}
