.home {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.title {
  font-family: 'Source Serif Pro', sans-serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 120%;

  margin-top: 3.5rem;
  text-align: center;

  @media (min-width:768px) {
    font-size: 55px;
  }
}

.main {
  display: grid;
  align-self: center;
  grid-template-columns: minmax(320px, 1fr);
  grid-template-rows: auto;
  max-width: 1440px;
  gap: 1.25rem;
  padding: 0 1.25rem;

  @media (min-width:768px) {
    grid-template-columns: 1fr minmax(320px, 1fr);
    grid-template-rows: 1fr auto;
  }
}

.information {
  display: flex;
  justify-content: flex-end;
}

.slideshow {
  order: -1;
  @media (min-width:768px) {
    order: 0;
    grid-area: auto / 2 / span 2 / 2;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
