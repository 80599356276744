.staticContent {
  display: flex;
  justify-content: center;
  background: #f7f5f2;
  align-self: stretch;
  padding: 3.5rem 1.25rem;

  &  a {
    &, &:visited {
      color: #2A382A;
    }
  }
}

.container {
  max-width: 1168px;
  flex: 1;
  gap: 1.25rem;
}

.primaryBox {
  max-width: 520px;
}

.title {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
}

.primaryText {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.secondaryBox {
  gap: 2rem;
  margin-bottom: 3.5rem;
  @media (min-width:768px) {
    display: flex;
  }
}

.subTitle {
  flex: 50% 1 1;
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  margin-block: 0;
}

.secondaryText {
  flex: 50% 1 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}