.loginModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.closeButton {
  margin-left: auto;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media (min-width:768px) {
    padding: 20px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  align-items: center;
  justify-content: center;

  @media (min-width:768px) {
    padding: 0 50px 40px;
  }
}

.title {
  font-family: 'Source Serif Pro', sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 120%;
  color: #00331E;
  margin-top: 20px;
}

.input {
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;

  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
  color: rgba(22, 29, 22, 0.6);
  margin-top: 1rem;
}

.text {
  margin-top: 1rem;
  max-width: 420px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #161D16;

  @media (min-width:768px) {
    font-size: 23px;
  }

}

.button {
  margin-top: 1.5rem;
  font-size: 20px;

  @media (min-width:768px) {
    font-size: 22px;
  }
}