.priceList {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;

  color: #2A382A;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.125rem;
  text-align: center;
}

.table {
  width: 100%;
  text-align: left;
  font-size: 12px;

  @media (min-width:768px) {
    font-size: 20px;
  }

  & tr {
    line-height: 120%;
    height: 3rem;

    @media (min-width:768px) {
     height: 2rem;
    }
  }
}
.description {
  margin-top: 1rem;
  font-style: italic;

  & p {
    margin-block: 0;
  }
}