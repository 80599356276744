.adminPage {
  width: 100%;
  max-width: 1060px;
  padding: 32px 190px;
}

.emails {
  display: flex;
  gap: 3rem;
}

.emailsColumn {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex:50%;
}

.title {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-size: 24px;
  line-height: 40px;
  color: #2A382A;
}

.table {
  margin-top: 2rem;
}

.formRow {
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

.input {
  flex-grow: 1;
}

.productName {
  width: 300px;
}

.productPriceCol, .productPriceInput {
  text-align: right;
}

.productPriceInput {
  max-width: 80px;
}

.buttonContainer, .buttonEmailContainer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.priceDescriptionContainer {
  font-style: italic;
  font-size: 70%;
  width: 80%;
}

.editor {
  margin-block-start: 2rem;
}

.row {
  & > td {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(22, 29, 22, 0.2);
  }
}


.saveButton, .downloadEmailsButton {

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
  background: #199B55;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.5rem;


  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color:white;

  cursor: pointer;
}

.downloadEmailsButton, .findButton {
  background: #f65611;
  padding: 0.5rem 3rem;
  margin-right: 20px;
  margin-top: 20px;
}

.buttonEmailContainer{
  border-top: 2px solid rgba(22, 29, 22, 0.3);    
  justify-content: flex-start;
}

.sentEmailsContainer {
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 2px solid rgba(22, 29, 22, 0.3);    
}

.findInput {
  margin-right: 0.5rem;

  padding: 8px 10px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 20rem;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 29, 22, 0.6);
}

.findButton {
    width: 1.5rem;
    display: inline-block;
    margin-top: 10px;
}