.informationButtons {
  display: flex;
  gap: 1.25rem;
  flex: 1;
  justify-content: center;

  @media (min-width:768px) {
    max-width: 530px;
    justify-content: flex-start;
  }
}

.loginButton {
  padding: 0.75rem 3.125rem;

  @media (min-width:768px) {
    padding: 0.75rem 1.5rem;
    font-size: 22px;
  }
}

.scrollButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  gap: 0.5rem;

  border: 2px solid rgba(22, 29, 22, 0.4);

  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;

  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  text-transform: uppercase;
  color: rgba(22, 29, 22, 0.6);

  cursor: pointer;

  @media (min-width:768px) {
    font-size: 16px;
    line-height: 24px;
  }
}



