.orderTable {
  background: #F7F5F2;
  margin-top: 3.5rem;

  overflow: auto;

  @media (min-width:768px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.table {
  width: 1024px;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  border-spacing: 0 0.5rem;

  @media (min-width:768px) {
    width: 100%;
  }
}

.tableHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
}

.productName {
  width: 8rem;
  text-align: left;
}

.productHeader {
  height: 3rem;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

