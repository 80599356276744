.orderCheckout {
  max-width: 32.875rem;
}

.title {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
}

.box {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(22, 29, 22, 0.2);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.rowNoBorder {
  border-bottom: none;
}
.bold {
  font-weight: 700;
}

.button {
  margin-top: 4rem;
  padding-block: 0.5rem;
}

.footerText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(22, 29, 22, 0.6);
  margin-top: 1.25rem;
}
