.printOrderEdited {
  background: #FFFFFF;
  border: 2px solid #f65611;  
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.header {
  display: flex;
  font-weight: 600;
  margin-bottom: 0.5rem;  
}

.headerItem {
  flex: auto;
}

.button {
  margin-top: 1rem;
}

.files {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2rem;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pdfSvg {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.shippingAddressInput {
  flex-grow: 1;
  margin-top: 0.5rem;
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 90%;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 29, 22, 0.6);
}

.quantityInput {
  flex-grow: 1;
  margin-top: 0.5rem;
  padding: 5px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 2rem;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 29, 22, 0.6);
}

.loader {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid;
  border-color: #F65611 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.saveButton {
  margin-top: 1rem;    
  background: #f65611;
}

.cancelEditButton {
  background: #b1b1b1;
  margin-top: 1rem;  
}

