.productDescription {
  margin-bottom: 4rem;

  @media (min-width:768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.image {
  max-width: 100%;
}

.title {
  font-family: 'Source Serif Pro';
  margin-block-end: 0;
  font-size: 24px;
  @media (min-width:768px) {
    font-size: 34px;
    margin-block-end: 1rem;
  }
}

.description {
  margin-block-end: 1rem;

  @media (min-width:768px) {
    font-size: 24px;
  }
}