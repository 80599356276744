.printOrder {
  background: #FFFFFF;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.header {
  display: flex;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.headerItem {
  flex: auto;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.button {
  margin-top: 1rem;
}

.files {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2rem;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pdfSvg {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.comments {
  margin-block: 2rem;
}

.comment {
  display: flex;
}

.commentDelete {
  margin-left: auto;
  cursor: pointer;
}

.commentActions {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.commentInput {
  flex-grow: 1;
  margin-right: 0.5rem;

  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 29, 22, 0.2);
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 29, 22, 0.6);
}

.addCommentButton {
  background: #7F0100;
}

.loader {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid;
  border-color: #F65611 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.editButton {
  background: #b1b1b1;
  margin-top: 1rem;  
}
