.homeAdminPage {
  width: 100%;
  max-width: 1060px;
  padding: 32px 190px;
}

.saveButton {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
  padding: 1rem 4rem;
  background: #199B55;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0.5rem;


  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color:white;

  cursor: pointer;
}

.label {
  margin-block-end: 0.25rem;
}
.block {
  margin-block-end: 2rem;
}

.input {
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: 18px
}

.textarea {
  margin-block-start: 0.5rem;
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: 18px
}