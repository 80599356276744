.confirmInvoiceModal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  max-width: 420px;
}


.buttons {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
}

.cancelButton {
  color: rgba(22, 29, 22, 0.6);
  background: white;
  border: 2px solid rgba(22, 29, 22, 0.4);
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
}